import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function statementUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    {key: 'orderType', label: '单据类型', sortable: true},
    {key: 'orderNo', label: '关联编号', sortable: true},
    {key: 'isRefuse', label: '有拒收', sortable: true},
    { key: 'result', label: '是否退货'},
    { key: 'time', label: '销售/退货业务日期'},
    { key: 'salesPrice', label: '销售金额'},
    { key: 'returnPrice', label: '退货金额'},
    { key: 'usedPrice', label: '已对账金额'},
    { key: 'statementPrice', label: '本次对账金额'},
    { key: 'remainPrice', label: '剩余金额'},
    {key: 'checkTime', label: '审核通过时间', sortable: true},
    // { key: 'reason', label: '退货原因'},
    { key: 'actions', label: '操作'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('statement_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})

  const editArray = ref([])



  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
        .dispatch('statement/searchOrder', {
          companyId:isEmpty(condition.value.channelName)?null:condition.value.channelName.value,
          storeId:isEmpty(condition.value.storeName)?null:condition.value.storeName.value,
          time:condition.value.time,
          statementId:config.statementId||condition.value.statementId,
          orderNo:condition.value.orderNo,
          type:config.type,
          edit:1,
          // companyId:16,
          // storeId:224,
          // time:'2022-12-01 至 2022-12-14',
        })
        .then(response => {
          const data = response.data.data
          for (let i = 0; i < editArray.value.length; i++) {
            data.push(editArray.value[i])
          }
          // editArray.value = []
          callback(data)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,
    editArray,

    // Extra Filters
  }
}
