<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>
                    请选择平台,店铺之后再查询
                  </strong></span>
                </div>
              </b-alert>
            </div>
          </b-col>
<!--          <b-col-->
<!--              cols="12"-->
<!--              md="6"-->
<!--          >-->
<!--            <div class="d-flex align-items-center justify-content-end">-->
<!--              <b-button-->
<!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                  variant="outline-primary"-->
<!--                  @click="advanced_search"-->
<!--                  v-if="readonly"-->
<!--              >-->
<!--                <feather-icon-->
<!--                    icon="PlusIcon"-->
<!--                    class="mr-50"-->
<!--                />-->
<!--                <span class="align-middle">高级搜索</span>-->
<!--              </b-button>-->
<!--            </div>-->
<!--          </b-col>-->
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col md="4" cols="12" class="mb-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.storeName"
                      label="label"
                      :options="storeArray"
                      class="select-size-sm"
                      @input="changeSelects($event,'store')"
                      placeholder="请选择店铺"
                  />
                </b-form-group>

              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="终端客户:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      :options="channelArray"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="condition.channelName"
                      @input="changeSelects($event,'channel')"
                      placeholder="请选择终端客户"
                  />
                  <!--                  :dropdown-should-open="function(VueSelect) {-->
                  <!--                  return VueSelect.search.length >=2 && VueSelect.open-->
                  <!--                  }"-->
                </b-form-group>

              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    variant="outline-success"
                    @click="showModal"
                >
                  <span class="align-right">增加单据</span>
                </b-button>

<!--                <b-button-->
<!--                    variant="outline-primary"-->
<!--                    @click="advanced_search"-->
<!--                >-->
<!--                  <span class="align-right">关闭</span>-->
<!--                </b-button>-->
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>


      <b-row>
        <b-col md="12" class="ml-2">
          <div class="inner-card-title">其他信息</div>
        </b-col>
        <b-col md="4" cols="12" class="mb-1">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="结算单号:"
              label-for="order_no"
              label-size="sm"
          >
            <b-form-input
                v-model="statement.statement_no"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="4" cols="12" class="mb-1">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="单据金额:"
              label-for="order_no"
              label-size="sm"
          >
            <b-form-input
                v-model="statementModule.statementPrice"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col md="4" cols="12" class="mb-1">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="对账金额:"
              label-for="order_no"
              label-size="sm"
          >
            <b-form-input
                v-model="statementModule.receivableTotal"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col md="4" cols="12" class="mb-1">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="对账日期:"
              label-for="order_no"
              label-size="sm"
              class="required"
          >
            <flat-pickr
                v-model="statementModule.time"
                class="form-control"
                placeholder="请选择对账日期"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="1"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="statement.remark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>

        <b-col md="12" class="ml-2">
          <div class="inner-card-title">费用信息</div>
        </b-col>

        <b-button
            variant="primary"
            @click="addCost"
            class="ml-2"
        >
          <span class="text-nowrap">添加</span>
        </b-button>
        <b-col md="10" class="ml-2">
          <b-table
              ref="refCostListTable"
              class="position-relative"
              :items="costList"
              responsive
              hover
              small
              :fields="costTableColumns"
              primary-key="id"
              show-empty
              empty-text="未找到记录"
          >

            <template v-slot:custom-foot="data">
              <b-tr>
                <b-td :colspan="2" variant="primary">
                  <span><strong>合计</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{costTotal.amount}}</strong></span>
                </b-td>
                <b-td :colspan="10" variant="primary">
                </b-td>
              </b-tr>
            </template>

            <template #cell(type)="data">
              <div style="width: 200px">
                <v-select
                    id="type"
                    :options="getCodeOptions(type==1?'statement_cost_type_online':'sales_cost_type')"
                    :clearable="true"
                    v-model="data.item.typeName"
                    @input="changeSelect(data.item,$event)"
                    class="select-size-sm"
                    placeholder="请选择科目"
                    :disabled="type==2"
                />
              </div>
            </template>

            <template #cell(amount)="data">
              <b-form-input
                  id="amount"
                  size="sm"
                  v-model="data.item.amount"
                  type="number"
                  style="width: 80px"
                  readonly
              />
            </template>

            <template #cell(soOrderNo)="data">
              {{ data.item.soOrderNo }}
            </template>

            <template #cell(brand)="data">
              {{ data.item.brand }}
            </template>

            <template #cell(store)="data">
              {{ data.item.store }}
            </template>

            <template #cell(channel)="data">
              {{ data.item.channel }}
            </template>

            <template #cell(costNo)="data">
              {{ data.item.costNo }}
            </template>

            <template #cell(updator)="data">
              {{ getCodeLabel('user', data.item.updator) }}
            </template>

            <template #cell(creator)="data">
              {{ getCodeLabel('user', data.item.creator) }}
            </template>

            <template #cell(status)="data">
              <b-badge
                  pill
                  :variant="`light-${getCodeColor('sales_cost_status', data.item.status)}`"
              >
                {{ getCodeLabel("sales_cost_status", data.item.status) }}
              </b-badge>
            </template>

            <template #cell(attachments)="data">
              <attachment-upload :theme="'preview-dropdown'"
                                 :attachment_id="'attachments'"
                                 :id="data.item.attachments"
                                 :object_type="'sales_cost'"
                                 :object_id="0"
                                 :readonly="true"
                                 @change=""
              />
            </template>

            <template #cell(remark)="data">
              {{ isEmpty(data.item.remark) ? '' : data.item.remark.substring(0, 10) }}
              <feather-icon
                  v-if="!isEmpty(data.item.remark)&&data.item.remark.length > 10"
                  icon="EyeIcon"
                  size="21"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.remark+
              '</div>'"
                  variant="success"
              />
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <!--单菜单-->
              <b-row>
                <b-link>
                  <feather-icon @click="del(data)"
                                icon="XSquareIcon"
                                size="16"
                                class="align-middle text-body"
                                v-if="isEmpty(data.item.soOrderNo)"
                  />
                </b-link>
              </b-row>

            </template>

          </b-table>
        </b-col>

      </b-row>

      <div class="demo-spacing-0 mt-2">
        <b-row>
          <b-button
              variant="outline-primary"
              type="reset"
              @click="selectAllRowsSearchTable"
              class="ml-1 mb-1"
          >
            <span class="align-right">全选</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
              @click="clearSelectedSearchTable"
              class="ml-1 mb-1"
          >
            <span class="align-right">取消全选</span>
          </b-button>

          <b-alert
              variant="primary"
              show
              class="ml-1 mt-1"
          >
            <div class="alert-body">
                  <span><strong>
                    已选中：{{showAlert.length}}行，合计销售金额：{{showAlert.salesPrice}}，退货金额：{{showAlert.returnPrice}}，已对账金额：{{showAlert.usedPrice}}，本次对账金额：{{showAlert.statementPrice}}（本数据仅为您提供快捷计算用）
                  </strong></span>
            </div>
          </b-alert>
        </b-row>
      </div>

      <b-tabs>
        <b-tab title="单据清单">

          <b-table
              sticky-header
              noCollapse
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="table"
              selectable
              selected-variant="success"
              select-mode="multi"
              @row-selected="onRowSelectedSearchTable"
          >

            <template v-slot:custom-foot="data">
              <b-tr>
                <b-td :colspan="6" variant="primary">
                  <span><strong>合计</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{itemTotal.salesPrice}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{itemTotal.returnPrice}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{itemTotal.usedPrice}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{itemTotal.statementPrice}}</strong></span>
                </b-td>
                <b-td :colspan="1" variant="primary">
                  <span><strong>{{itemTotal.remainPrice}}</strong></span>
                </b-td>
                <b-td :colspan="2" variant="primary">
                </b-td>
              </b-tr>
            </template>

            <template #cell(checkTime)="data">
              {{ toDate(data.item.ext.checkTime) }}
            </template>
            <!-- Column: Type -->
            <template #cell(orderType)="data">
              {{ data.item.ext.type == 1 ? '销售' : '退货' }}
            </template>

            <template #cell(orderNo)="data">
              <b-link
                  :to="{ name: data.item.ext.name, query: { id: data.item.ext.orderId,readonly:1 },params:{readonly:1} }"
                  class="font-weight-bold text-nowrap"
                  target="_blank"
              >
                {{ data.item.ext.orderNo }}
              </b-link>
              <br>
              <b-link
                  :to="{ name: data.item.ext.preName, query: { id: data.item.ext.preOrderId,readonly:1 },params:{readonly:1} }"
                  class="font-weight-bold text-nowrap"
                  target="_blank"
              >
                {{ data.item.ext.preOrderNo }}
              </b-link>
            </template>

            <template #cell(isRefuse)="data">
<!--              {{ data.item.ext.isRefuse == 1 ? '是' : '否' }}-->
              <feather-icon
                  icon="CheckIcon"
                  size="21"
                  v-if="data.item.ext.isRefuse==1"
              />
              <feather-icon
                  icon="XIcon"
                  size="21"
                  v-else
              />
            </template>

            <template #cell(time)="data">
              {{ toDate(data.item.ext.time) }}
            </template>

            <template #cell(salesPrice)="data">
              {{ data.item.ext.salesPrice }}
            </template>

            <template #cell(returnPrice)="data">
              {{ data.item.ext.returnPrice }}
            </template>

            <template #cell(usedPrice)="data">
              {{ data.item.ext.usedPrice }}
            </template>

            <template #cell(result)="data">
              <feather-icon
                  icon="CheckIcon"
                  size="21"
                  v-if="data.item.ext.result===true"
              />
              <feather-icon
                  icon="XIcon"
                  size="21"
                  v-if="data.item.ext.result===false"
              />
            </template>

            <template #cell(statementPrice)="data">
              <b-form-input
                  v-model="data.item.ext.statementPrice"
                  type="number"
                  :readonly="type==2"
              >

              </b-form-input>
            </template>

            <template #cell(remainPrice)="data">
              {{
                data.item.ext.type === 1 ? (data.item.ext.salesPrice - data.item.ext.statementPrice - data.item.ext.usedPrice + data.item.ext.thisPrice).toFixed(2) : (data.item.ext.returnPrice - data.item.ext.statementPrice - data.item.ext.usedPrice + data.item.ext.thisPrice).toFixed(2)
              }}
            </template>

            <template #cell(reason)="data">
              {{ getCodeLabel('sales_returnbound_reasontype', data.item.ext.reason) }}

            </template>

            <template #cell(actions)="data">
              <!--单菜单-->
              <b-row>
                <b-link>
                  <feather-icon @click="delItem(data)"
                                icon="XSquareIcon"
                                size="16"
                                class="align-middle text-body"
                                v-if="data.item.ext.orderNo.indexOf('HZ')==-1"
                  />
                </b-link>
              </b-row>

            </template>

          </b-table>

        </b-tab>
        <b-tab title="商品清单">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              @click="searchProduct"
              class="mb-2"
          >
            <span class="align-middle">查询商品</span>
          </b-button>
          <b-table
              ref="refProductListTable"
              class="position-relative"
              :items="productList"
              responsive
              hover
              small
              :fields="productTableColumns"
              primary-key="id"
              show-empty
              empty-text="未找到记录"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(warehouse)="data">
              {{ getCodeLabel('warehouse', data.item.ext.warehouseId) }}
            </template>

            <template #cell(code)="data">
              {{ data.item.ext.product.code }}
            </template>

            <template #cell(name)="data">
              {{ data.item.ext.product.name }}
            </template>

            <template #cell(cost)="data">
              {{ data.item.ext.cost }}
            </template>

            <template #cell(soQty)="data">
              {{ data.item.ext.soQty }}
            </template>

            <template #cell(soSum)="data">
              {{ data.item.ext.soSum }}
            </template>

            <template #cell(srExpectQty)="data">
              {{ data.item.ext.expectQty }}
            </template>

            <template #cell(srSum)="data">
              {{ data.item.ext.srSum }}
            </template>

            <template #cell(srQty)="data">
              {{ data.item.ext.srQty }}
            </template>

            <template #cell(total)="data">
              {{ data.item.ext.total }}
            </template>

          </b-table>
        </b-tab>
      </b-tabs>

      <b-row>
        <b-col md="10"></b-col>
        <b-col md="2">
          <b-button
              variant="success"
              @click="save"
              class="ml-2 mt-1"
              v-if="readonly"
          >
            <span class="text-nowrap">保存</span>
          </b-button>
          <b-button
              variant="primary"
              @click="submit"
              class="ml-2 mt-1"
              v-if="readonly"
          >
            <span class="text-nowrap">提交</span>
          </b-button>
          <b-button
              variant="secondary"
              @click="back"
              class="ml-2 mt-1"
          >
            <span class="text-nowrap">返回</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
        id="modal-add"
        ok-title="确认"
        @ok="editSave"
        cancel-title="取消"
        ok-variant="success"
        centered
        size="xl"
        title="增加"
        ref="addModal"
        @close="function() {
          conditionModal.orderNo = null
        }"
        @cancel="function() {
          conditionModal.orderNo = null
        }"
    >
      <b-alert
          variant="primary"
          show
      >
        <div class="alert-body">
          <span><strong>
            点击勾选
          </strong></span>
        </div>
      </b-alert>

      <b-card>
        <b-form
            @submit.prevent="submitSearch"
            @reset.prevent="resetCondition"
        >
          <b-row>

            <b-col
                cols="12"
                md="4"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="订单类型"
                  label-for="qtyIsZero"
                  label-size="sm"
              >
                <v-select
                    id="qtyIsZero"
                    :options="[{label:'销售',value:1},{label:'退货',value:2}]"
                    v-model="conditionModal.type"
                    name="qtyIsZero"
                    class="select-size-sm"
                    placeholder="请选择订单类型"
                />
              </b-form-group>
            </b-col>

            <b-col md="4" cols="12" class="mb-2">
              <xy-input-button label="业务时间"
                               type="time"
                               v-on:change="fromChildren_modal($event,['time'])"
                               :params="['time']"
                               :value="conditionModal.time"
              >
              </xy-input-button>
            </b-col>

            <b-col md="4" cols="12" class="mb-2">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="销售/退货单号:"
                  label-for="order_no"
                  label-size="sm"
              >
                <b-form-input
                    v-model="conditionModal.orderNo"
                    name="orderNo"
                    class="d-inline-block mr-1"
                    size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col
                cols="12"
                md="12"
                class="demo-inline-spacing d-flex align-items-center justify-content-end"
            >
              <b-button
                  variant="outline-primary"
                  type="submit"
              >
                <span class="align-right">查询</span>
              </b-button>

              <b-button
                  variant="outline-primary"
                  type="reset"
              >
                <span class="align-right">重置</span>
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <b-alert
          variant="primary"
          show
          class="ml-1 mt-1"
      >
        <div class="alert-body">
                  <span><strong>
                    已选中：{{ showTableAlert.length }}行，合计销售金额：{{ showTableAlert.salesPrice }}，退货金额：{{ showTableAlert.returnPrice }}，已对账金额：{{ showTableAlert.usedPrice }}（本数据仅为您提供快捷计算用）
                  </strong></span>
        </div>
      </b-alert>

      <b-button
          variant="outline-primary"
          type="reset"
          @click="selectAllRows"
          class="ml-1 mb-1"
      >
        <span class="align-right">全选</span>
      </b-button>

      <b-button
          variant="outline-primary"
          type="reset"
          @click="clearSelected"
          class="ml-1 mb-1"
      >
        <span class="align-right">取消全选</span>
      </b-button>

      <b-table
          sticky-header
          noCollapse
          ref="refListAddTable"
          class="position-relative"
          :items="editModalList"
          responsive
          hover
          small
          :fields="editTableColumns"
          primary-key="id"
          show-empty
          empty-text="点击查询按钮后展示单据"
          selectable
          selected-variant="success"
          select-mode="multi"
          @row-selected="onRowEditSelected"
          v-model="editTable"
          :tbody-tr-class="rowClass"
      >

        <template #cell(checkTime)="data">
          {{ toDate(data.item.ext.checkTime) }}
        </template>

        <!-- Column: Type -->
        <template #cell(orderType)="data">
          {{ data.item.ext.type == 1 ? '销售' : '退货' }}
        </template>

        <template #cell(orderNo)="data">
          <b-link
              :to="{ name: data.item.ext.name, query: { id: data.item.ext.orderId,readonly:1 },params:{readonly:1} }"
              class="font-weight-bold text-nowrap"
              target="_blank"
          >
            {{ data.item.ext.orderNo }}
          </b-link>
          <br>
          <b-link
              :to="{ name: data.item.ext.preName, query: { id: data.item.ext.preOrderId,readonly:1 },params:{readonly:1} }"
              class="font-weight-bold text-nowrap"
              target="_blank"
          >
            {{ data.item.ext.preOrderNo }}
          </b-link>
        </template>

        <template #cell(isRefuse)="data">
<!--          {{ data.item.ext.isRefuse == 1 ? '是' : '否' }}-->
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-if="data.item.ext.isRefuse==1"
          />
          <feather-icon
              icon="XIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(time)="data">
          {{ toDate(data.item.ext.time) }}
        </template>

        <template #cell(salesPrice)="data">
          {{ data.item.ext.salesPrice }}
        </template>

        <template #cell(returnPrice)="data">
          {{ data.item.ext.returnPrice }}
        </template>

        <template #cell(usedPrice)="data">
          {{ data.item.ext.usedPrice }}
        </template>

        <template #cell(statementPrice)="data">
          <b-form-input
              v-model="data.item.ext.statementPrice"
              type="number"
          >

          </b-form-input>
        </template>

        <template #cell(remainPrice)="data">
          {{
            data.item.ext.type === 1 ? (data.item.ext.salesPrice - data.item.ext.usedPrice).toFixed(2) : (data.item.ext.returnPrice - data.item.ext.usedPrice).toFixed(2)
          }}
        </template>

        <template #cell(reason)="data">
          {{ getCodeLabel('sales_returnbound_reasontype', data.item.ext.reason) }}
        </template>
      </b-table>
      <!--      <b-button-->
      <!--          variant="success"-->
      <!--          @click="editSave"-->
      <!--          class="ml-2"-->
      <!--      >-->
      <!--        <span class="text-nowrap">保存</span>-->
      <!--      </b-button>-->
    </b-modal>

    <b-modal
        id="modal-salesCost"
        ok-title="确认"
        @ok="salesCostSave"
        cancel-title="取消"
        ok-variant="success"
        centered
        size="xl"
        title="增加"
        ref="salesCostModal"
    >
      <sales-cost-select-list ref="salesCostSelect"
                              :item="table"
                              :storeId="isEmpty(condition.storeName)?null:parseInt(condition.storeName.value)"
                              :channelId="isEmpty(condition.channelName)?null:parseInt(condition.channelName.value)"
                              :statementId="statement.statement_id"
                              v-on:cost="addCostFromChildren"


      >

      </sales-cost-select-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty, isEmptys, isEmptyNew, addNumber
} from '@core/utils/filter'
import modalUseList from './modalUseList'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import StatementCostList from "@/views/apps/statementcost/StatementCostList";
import {useToast} from "vue-toastification/composition";
import statementStore from "@/views/apps/statement/statementStore";
import XyCheckbox from "@/views/components/xy/XyCheckbox";
import StatementbycostList from "@/views/apps/statementbycost/StatementbycostList";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import storeStore from "@/views/apps/store/storeStore";
import SalesCostSelectList from "@/views/apps/salescost/modal/SalesCostSelectList";
import companyStore from "@/views/apps/company/companyStore";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {getUserData} from "@/auth/utils";
import storesalesStore from "@/views/apps/storesales/storesalesStore";

export default {
  components: {
    AttachmentUpload,
    SalesCostSelectList,
    StatementbycostList,
    XyCheckbox,
    BTabs,
    BTab,
    StatementCostList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statement/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
      setTimeout(() => {
        this.$refs.refListTable.selectAllRows()
      }, 2000)
    },
    resetCondition() {
      this.conditionModal = {}
      this.submitSearch();
    },
    changeSelect: function (params, event) {
      params['type'] = event == null ? null : event.value
      params['typeName'] = event == null ? null : event.label
    },
    onRowEditSelected(items) {
      this.selected = items
    },
    onRowSelected(items) {
      this.checked = items
    },
    selectAllRows() {
      this.$refs.refListAddTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListAddTable.clearSelected()
    },
    rowClass(item, type) {
      if (!item || type !== 'row') { return }
      if (item.ext.orderNo.indexOf('HZ')!=-1) { return  'table-danger'}
    },
    onRowSelectedSearchTable(items) {
      this.searchTable = items
    },
    selectAllRowsSearchTable() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelectedSearchTable() {
      this.$refs.refListTable.clearSelected()
    },
  },
  data() {
    return {
      productTableColumns: [
        {key: 'index', label: '序号'},
        {key: 'warehouse', label: '仓库'},
        {key: 'code', label: '商品69码'},
        {key: 'name', label: '名称'},
        {key: 'cost', label: '含税售价'},
        {key: 'soQty', label: '售卖数量'},
        {key: 'soSum', label: '售卖小计'},
        {key: 'srExpectQty', label: '退货数量'},
        {key: 'srSum', label: '退货小计'},
        {key: 'srQty', label: '实际数量'},
        {key: 'total', label: '价税小计'},
      ],
      costTableColumns: [
        {key: 'costNo', label: '费用单编号'},
        {key: 'type', label: '费用科目'},
        {key: 'amount', label: '扣减金额'},
        {key: 'store', label: '店铺'},
        {key: 'channel', label: '终端客户'},
        {key: 'soOrderNo', label: '关联销售单号'},
        {key: 'brand', label: '品牌'},
        {key: 'status', label: '状态'},
        {key: 'creator', label: '申请人'},
        {key: 'updator', label: '审核人'},
        {key: 'remark', label: '备注'},
        {key: 'attachments', label: '附件'},
        {key: 'actions', label: '操作'},
      ],
      editTableColumns: [
        {key: 'orderType', label: '单据类型', sortable: true},
        {key: 'orderNo', label: '关联编号', sortable: true},
        {key: 'isRefuse', label: '有拒收', sortable: true},
        {key: 'time', label: '销售/退货业务日期', sortable: true},
        {key: 'salesPrice', label: '销售金额', sortable: true},
        {key: 'returnPrice', label: '退货金额', sortable: true},
        {key: 'usedPrice', label: '已对账金额', sortable: true},
        {key: 'remainPrice', label: '剩余金额', sortable: true},
        {key: 'checkTime', label: '审核通过时间', sortable: true},
      ],
      advanced_search_modal: true,
      statementId: 0,
      productList: [],
      statementModule: {
        statementPrice: 0,
        receivableTotal: 0,
      },
      costList: [],
      checked: [],
      table: [],
      selected: [],
      editModalList: [],
      editTable: [],
      type: '',
      isEdit: '',
      statement: {},
      readonly: true,
      id: 0,
      channelArray: [],
      storeArray: [],
      conditionModal: {},
      itemTotal:{},
      costTotal:{},
      searchTable:[],
      showAlert:{
        length:0,
        salesPrice:0,
        returnPrice:0,
        usedPrice:0,
        statementPrice:0,
      },
      showTableAlert: {
        length: 0,
        salesPrice: 0,
        returnPrice: 0,
        usedPrice: 0,
        statementPrice: 0,
      },
    }
  },
  setup() {
    const toast = useToast()
    const router = useRouter()

    //页面通信
    window.addEventListener('storage', function (e) {
      if (e.key === 'sc-msg') {
        const data = JSON.parse(e.newValue);
        console.log(data);
      }
    });

    if (!store.hasModule('statement')) store.registerModule('statement', statementStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statement')) store.unregisterModule('statement')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const fromChildren_modal = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.conditionModal[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const searchProduct = function () {
      let soArray = []
      let srArray = []
      for (let i = 0; i < this.table.length; i++) {
        if (this.table[i].ext.type === 1) {
          soArray.push(this.table[i].ext.id)
        } else {
          srArray.push(this.table[i].ext.id)
        }
      }
      store.dispatch('statement/searchProduct', {soIds: soArray.join(","), srIds: srArray.join(",")}).then(res => {
        this.productList = res.data.data
      })
    }

    const del = function (params) {
      this.costList.splice(params.index, 1)
    }

    const addCost = function () {
      if (this.type == 1) {
        this.costList.push({})
      } else {
        this.$refs["salesCostModal"].show()
      }
    }

    const save = function () {
      this.readonly = false
      if (isEmpty(condition.value.channelName)) {
        toast.error("请选择平台名称!")
        this.readonly = true
        return false
      }
      if (isEmpty(condition.value.storeName)) {
        toast.error("请选择店铺名称!")
        this.readonly = true
        return false
      }
      if (isEmpty(this.statementModule.time)) {
        toast.error("请填写对账日期!")
        this.readonly = true
        return false
      }
      if (this.table.length == 0) {
        toast.error("请选择单据!")
        this.readonly = true
        return false
      }

      let itemArray = []
      for (let i = 0; i < this.table.length; i++) {
        if (isEmptys(this.table[i].ext.statementPrice)) {
          toast.error("本次对账金额必填!")
          this.readonly = true
          return false
        }
        itemArray.push({
          order_id: this.table[i].ext.id,
          order_no: this.table[i].ext.orderNo,
          total_amount: this.table[i].ext.statementPrice,
          item_id: this.table[i].ext.statementItemId
        })
      }
      for (let i = 0; i < this.costList.length; i++) {
        if (isEmpty(this.costList[i].type)) {
          toast.error("费用科目必填!")
          this.readonly = true
          return false
        }
        if (isEmptys(this.costList[i].amount)) {
          toast.error("扣减金额必填!")
          this.readonly = true
          return false
        }
        if (!isEmpty(this.costList[i].soOrderNo) && this.table.filter(e => e.ext.orderNo == this.costList[i].soOrderNo).length == 0) {
          toast.error(this.costList[i].soOrderNo + "单据和费用单不匹配!")
          this.readonly = true
          return false
        }
      }
      store.dispatch('statement/save', {
        channel_id: condition.value.channelName.value,
        channel_name: condition.value.channelName.label,
        store_name: condition.value.storeName.label,
        store_id: condition.value.storeName.value,
        type: this.type,
        time: this.statementModule.time,
        statementByCost: JSON.stringify(this.costList),
        item: JSON.stringify(itemArray),
        statement_id: this.statement.statement_id,
        order_time: condition.value.time,
        remark: this.statement.remark
      }).then(res => {
        if (res.data.code == 0) {
          toast.success("创建成功!")
          this.editArray = []
          if (isEmpty(router.route.value.query.id)) {
            this.condition.statementId = res.data.data.statement_id
            this.statement = res.data.data
            this.isEdit = true
          }
          refetchData()
        } else {
          toast.error(res.data.data)
        }
        this.readonly = true
      })
    }

    const submit = function () {
      this.readonly = false
      if (isEmpty(condition.value.channelName)) {
        toast.error("请选择平台名称!")
        this.readonly = true
        return false
      }
      if (isEmpty(condition.value.storeName)) {
        toast.error("请选择店铺名称!")
        this.readonly = true
        return false
      }
      if (isEmpty(this.statementModule.time)) {
        toast.error("请填写对账日期!")
        this.readonly = true
        return false
      }
      if (this.table.length == 0) {
        toast.error("请选择单据!")
        this.readonly = true
        return false
      }

      let itemArray = []
      for (let i = 0; i < this.table.length; i++) {
        if (isEmptys(this.table[i].ext.statementPrice)) {
          toast.error("本次对账金额必填!")
          this.readonly = true
          return false
        }
        itemArray.push({
          order_id: this.table[i].ext.id,
          order_no: this.table[i].ext.orderNo,
          total_amount: this.table[i].ext.statementPrice,
          item_id: this.table[i].ext.statementItemId
        })
      }
      for (let i = 0; i < this.costList.length; i++) {
        if (isEmpty(this.costList[i].type)) {
          toast.error("费用科目必填!")
          this.readonly = true
          return false
        }
        if (isEmptys(this.costList[i].amount)) {
          toast.error("扣减金额必填!")
          this.readonly = true
          return false
        }
        if (!isEmpty(this.costList[i].soOrderNo) && this.table.filter(e => e.ext.orderNo == this.costList[i].soOrderNo).length == 0) {
          toast.error(this.costList[i].soOrderNo + "单据和费用单不匹配!")
          this.readonly = true
          return false
        }
      }
      store.dispatch('statement/save', {
        channel_id: condition.value.channelName.value,
        channel_name: condition.value.channelName.label,
        store_name: condition.value.storeName.label,
        store_id: condition.value.storeName.value,
        type: this.type,
        time: this.statementModule.time,
        statementByCost: JSON.stringify(this.costList),
        item: JSON.stringify(itemArray),
        statement_id: this.statement.statement_id,
        remark: this.statement.remark
      }).then(res => {
        if (res.data.code == 0) {
          store.dispatch('statement/changeStatus', {id: res.data.data.statement_id, status: 2}).then(res => {
            if (res.data.code == 0) {
              toast.success("提交成功")
              this.$router.push({name: 'apps-statement-ol-list', query: {type: this.type}});
            } else {
              toast.error(res.data.data)
            }
            this.readonly = true
          })
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const showModal = function () {
      this.$refs['addModal'].show()
    }

    const submitSearch = function () {
      console.log(condition,this.conditionModal)
      if (isEmpty(condition.value.channelName)) {
        toast.error("请选择终端客户")
        return
      }
      if (isEmpty(condition.value.storeName)) {
        toast.error("请选择店铺")
        return
      }
      store.dispatch('statement/searchOrder', {
        companyId: condition.value.channelName.value,
        storeId: condition.value.storeName.value,
        time: this.conditionModal.time,
        orderNo: this.conditionModal.orderNo,
        orderType: isEmpty(this.conditionModal.type) ? null : this.conditionModal.type.value
      })
          .then(res => {
            this.editModalList = res.data.data
          })
    }

    const editSave = function () {
      s:
          for (let i = 0; i < this.selected.length; i++) {
            if (this.selected[i].ext.orderNo.indexOf('HZ')!=-1){
              continue;
            }
            for (let j = 0; j < this.editArray.length; j++) {
              if (this.selected[i].ext.orderNo == this.editArray[j].ext.orderNo) {
                continue s;
              }
            }
            this.editArray.push(this.selected[i])
            if (!isEmpty(this.selected[i].ext.srModule)){
              this.editArray.push(this.selected[i].ext.srModule)
            }
            const list = this.selected[i].ext.salesCostList
            if (!isEmpty(list)){
              for (let j = 0; j < list.length; j++) {
                if (this.costList.filter(e => e.sales_cost_id == list[j].cost_id).length == 0) {
                  this.costList.push({
                    type: list[j].type,
                    amount: list[j].amount,
                    typeName: getCodeLabel('sales_cost_type', list[j].type),
                    sales_cost_id: list[j].cost_id,
                    soOrderNo: list[j].ext.soOrderNo,
                    brand: list[j].brand_name,
                    store: list[j].store_name,
                    channel: list[j].channel_name,
                    costNo: '['+list[j].cost_id+']'+list[j].cost_no,
                    remark: list[j].remark,
                    attachments: list[j].attachments,
                    status:list[j].status,
                    creator:list[j].creator,
                    updator:list[j].updator,
                  })
                }
              }
            }
          }
      // this.editArray = this.selected
      this.start++
      this.$refs['addModal'].hide()
      this.conditionModal.orderNo = null
    }

    const changeSelects = function (event, params) {
      if (params === 'store') {
        this.channelArray = []
        this.condition.channelName = []
        store.dispatch('company/search', {
          status: 4,
          state: 1,
          type: 6,
          store_id: isEmpty(this.condition.storeName) ? 0 : this.condition.storeName.value
        })
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.channelArray.push({"label": data[i].company_name, "value": data[i].company_id})
              }
            })
      }

      this.$forceUpdate()
    }

    const back = function () {
      this.$router.push({
        name: this.type == 1 ? 'apps-statement-ol-list' : 'apps-statement-list',
        query: {type: this.type}
      });
    }

    const storeSearch = function (companyId, all, _this) {
      store.dispatch('store/search', {company_id: 90, all: all})
          .then(response => {
            let data = response.data.data.list
            for (let i = 0; i < data.length; i++) {
              _this.storeArray.push({
                'key': i,
                "label": data[i].store_name + "(" + getCodeLabel('store_store_status', data[i].store_status) + ")",
                "value": data[i].store_id
              })
            }

            if (!_this.isEdit) {
              _this.condition.storeName = {
                "label": data[0].store_name + "(" + getCodeLabel('store_store_status', data[0].store_status) + ")",
                "value": data[0].store_id
              }

              _this.channelArray = []
              _this.condition.channelName = []
              store.dispatch('company/search', {
                status: 4,
                state: 1,
                type: 6,
                store_id: isEmpty(_this.condition.storeName) ? 0 : _this.condition.storeName.value
              })
                  .then(response => {
                    let data = response.data.data.list
                    for (let i = 0; i < data.length; i++) {
                      _this.channelArray.push({"label": data[i].company_name, "value": data[i].company_id})
                    }
                  })
            }
          })


    }

    const salesCostSave = function () {
      const select = this.$refs.salesCostSelect.getSelect()
      for (let i = 0; i < select.length; i++) {
        if (this.costList.filter(e => e.sales_cost_id == select[i].cost_id).length == 0) {
          this.costList.push({
            type: select[i].type,
            amount: select[i].amount,
            typeName: getCodeLabel('sales_cost_type', select[i].type),
            sales_cost_id: select[i].cost_id,
            soOrderNo: select[i].ext.soOrderNo,
            brand: select[i].brand_name,
            store: select[i].store_name,
            channel: select[i].channel_name,
            costNo: '['+select[i].cost_id+']'+select[i].cost_no,
            remark: select[i].remark,
            attachments: select[i].attachments,
            status:select[i].status,
            creator:select[i].creator,
            updator:select[i].updator,
          })
        }
      }
    }

    const delItem = function(data) {
      const array = []
      for (let i = 0; i < this.costList.length; i++) {
        if (this.costList[i].soOrderNo != data.item.ext.orderNo){
          array.push(this.costList[i])
        }
      }
      this.costList = array
      this.table.splice(data.index, 1)
      if (!isEmpty(data.item.ext.srModule)){
        let j = -1
        for (let i = 0; i < this.table.length; i++) {
          if (this.table[i].ext.orderNo==data.item.ext.srModule.ext.orderNo){
              j = i
            break
          }
        }
        if (j!==-1){
          this.table.splice(j, 1)
        }
        for (let i = 0; i < this.editArray.length; i++) {
          if (data.item.ext.srModule.ext.orderNo == this.editArray[i].ext.orderNo){
            this.editArray.splice(i,1)
            break
          }
        }
      }
      for (let i = 0; i < this.editArray.length; i++) {
        if (data.item.ext.orderNo == this.editArray[i].ext.orderNo){
          this.editArray.splice(i,1)
          break
        }
      }
    }

    const addCostFromChildren = function (params) {
      if (!isEmpty(params)){
        this.costList.push({
          type: params.type,
          amount: params.amount,
          typeName: getCodeLabel('sales_cost_type', params.type),
          sales_cost_id: params.cost_id,
          soOrderNo: params.ext.soOrderNo,
          brand: params.brand_name,
          store: params.store_name,
          channel: params.channel_name,
          costNo: '['+params.cost_id+']'+params.cost_no,
          remark: params.remark,
          attachments: params.attachments,
          status:params.status,
          creator:params.creator,
          updator:params.updator,
        })
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      editArray,

      // UI
    } = modalUseList({
      statementId: router.route.value.query.id || 0,
      type: router.route.value.query.type || 0,
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      editArray,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      addNumber,
      // UI
      toTime,
      toDate,
      isEmpty,
      isEmptys,
      condition,
      advanced_search,
      fromChildren,
      searchProduct,
      del,
      addCost,
      save,
      showModal,
      editSave,
      submit,
      isEmptyNew,
      changeSelects,
      back,
      storeSearch,
      salesCostSave,
      submitSearch,
      fromChildren_modal,
      delItem,
      addCostFromChildren,

    }
  },
  computed: {
    computeCost() {
      let data = 0;
      for (let i = 0; i < this.costList.length; i++) {
        data = data + parseFloat(isEmpty(this.costList[i].amount) ? 0 : this.costList[i].amount)
      }
      return data.toFixed(2)
    }
  },
  watch: {
    table: {
      handler(newVal, oldVal) {
        let price = 0;
        this.itemTotal = {}
        for (let i = 0; i < newVal.length; i++) {
          price = price + parseFloat(isEmpty(newVal[i].ext.statementPrice) ? 0 : newVal[i].ext.statementPrice)
          this.itemTotal.salesPrice = addNumber(this.itemTotal.salesPrice,newVal[i].ext.salesPrice)
          this.itemTotal.returnPrice = addNumber(this.itemTotal.returnPrice,newVal[i].ext.returnPrice)
          this.itemTotal.usedPrice = addNumber(this.itemTotal.usedPrice,newVal[i].ext.usedPrice)
          this.itemTotal.statementPrice = addNumber(this.itemTotal.statementPrice,newVal[i].ext.statementPrice)
          this.itemTotal.remainPrice = addNumber(this.itemTotal.remainPrice,newVal[i].ext.remainPrice)
        }
        this.statementModule.statementPrice = price.toFixed(2)
        this.statementModule.receivableTotal = (price - this.computeCost).toFixed(2)
        this.$forceUpdate()
      },
      deep: true

    },
    costList: {
      handler(newVal, oldVal) {
        this.costTotal = {}
        for (let i = 0; i < newVal.length; i++) {
          this.costTotal.amount = addNumber(this.costTotal.amount,newVal[i].amount)
        }
        this.statementModule.receivableTotal = (this.statementModule.statementPrice - this.computeCost).toFixed(2)
      },
      deep: true
    },
    searchTable:{
      handler(newVal, oldVal) {
        this.showAlert = {
          length:0,
          salesPrice:0,
          returnPrice:0,
          usedPrice:0,
          statementPrice:0,
        }
        for (let i = 0; i < newVal.length; i++) {
          this.showAlert.salesPrice = this.showAlert.salesPrice+parseFloat(isEmpty(newVal[i].ext.salesPrice)?0:newVal[i].ext.salesPrice)
          this.showAlert.returnPrice = this.showAlert.returnPrice+parseFloat(isEmpty(newVal[i].ext.returnPrice)?0:newVal[i].ext.returnPrice)
          this.showAlert.usedPrice = this.showAlert.usedPrice+parseFloat(isEmpty(newVal[i].ext.usedPrice)?0:newVal[i].ext.usedPrice)
          this.showAlert.statementPrice = this.showAlert.statementPrice+parseFloat(isEmpty(newVal[i].ext.statementPrice)?0:newVal[i].ext.statementPrice)
        }
        this.showAlert.length = newVal.length
        this.showAlert.salesPrice = this.showAlert.salesPrice.toFixed(2)
        this.showAlert.returnPrice = this.showAlert.returnPrice.toFixed(2)
        this.showAlert.usedPrice = this.showAlert.usedPrice.toFixed(2)
        this.showAlert.statementPrice = this.showAlert.statementPrice.toFixed(2)
      },
      deep: true
    },
    selected:{
      handler(newVal, oldVal) {
        this.showTableAlert = {
          length: 0,
          salesPrice: 0,
          returnPrice: 0,
          usedPrice: 0,
          statementPrice: 0,
        }
        for (let i = 0; i < newVal.length; i++) {
          this.showTableAlert.salesPrice = this.showTableAlert.salesPrice + parseFloat(isEmpty(newVal[i].ext.salesPrice) ? 0 : newVal[i].ext.salesPrice)
          this.showTableAlert.returnPrice = this.showTableAlert.returnPrice + parseFloat(isEmpty(newVal[i].ext.returnPrice) ? 0 : newVal[i].ext.returnPrice)
          this.showTableAlert.usedPrice = this.showTableAlert.usedPrice + parseFloat(isEmpty(newVal[i].ext.usedPrice) ? 0 : newVal[i].ext.usedPrice)
          this.showTableAlert.statementPrice = this.showTableAlert.statementPrice + parseFloat(isEmpty(newVal[i].ext.statementPrice) ? 0 : newVal[i].ext.statementPrice)
        }
        this.showTableAlert.length = newVal.length
        this.showTableAlert.salesPrice = this.showTableAlert.salesPrice.toFixed(2)
        this.showTableAlert.returnPrice = this.showTableAlert.returnPrice.toFixed(2)
        this.showTableAlert.usedPrice = this.showTableAlert.usedPrice.toFixed(2)
        this.showTableAlert.statementPrice = this.showTableAlert.statementPrice.toFixed(2)
      },
      deep: true
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.type = this.$route.query.type || 0;
    this.readonly = this.$route.query.readonly;
    if (this.$route.query.readonly == 'false'){
      this.readonly = false
    }
    this.isEdit = (this.id > 0)
    if (this.isEdit) {
      store.dispatch('statement/search', {statementId: this.$route.query.id || '', type: this.type}).then(res => {
        this.statement = res.data.data.list[0]
        this.condition.channelName = getCode('company', this.statement.channel_id)
        this.condition.storeName = {
          "label": this.statement.store_name + "(" + getCodeLabel('store_store_status', this.statement.ext.store.store_status) + ")",
          "value": this.statement.store_id
        }
        store.dispatch('company/search', {
          status: 4,
          state: 1,
          type: 6,
          store_id: isEmpty(this.condition.storeName) ? 0 : this.condition.storeName.value,
        })
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.channelArray.push({"label": data[i].company_name, "value": data[i].company_id})
              }
            })
        this.condition.time = this.statement.order_time
        this.statementModule.time = toDate(this.statement.statement_over_day - (this.statement.statement_over * 86400))
        if (!isEmpty(this.statement.ext.statementbycost)) {
          for (let i = 0; i < this.statement.ext.statementbycost.length; i++) {
            this.statement.ext.statementbycost[i].typeName = getCodeLabel('sales_cost_type', this.statement.ext.statementbycost[i].type)
            this.statement.ext.statementbycost[i].soOrderNo = this.statement.ext.statementbycost[i].ext.soOrderNo
            this.statement.ext.statementbycost[i].sales_cost_id = this.statement.ext.statementbycost[i].ext.salesCost.cost_id
            this.statement.ext.statementbycost[i].brand = this.statement.ext.statementbycost[i].ext.salesCost.brand_name
            this.statement.ext.statementbycost[i].store = this.statement.ext.statementbycost[i].ext.salesCost.store_name
            this.statement.ext.statementbycost[i].channel = this.statement.ext.statementbycost[i].ext.salesCost.channel_name
            this.statement.ext.statementbycost[i].costNo = '['+this.statement.ext.statementbycost[i].ext.salesCost.cost_id+']'+this.statement.ext.statementbycost[i].ext.salesCost.cost_no
            this.statement.ext.statementbycost[i].remark = this.statement.ext.statementbycost[i].ext.salesCost.remark
            this.statement.ext.statementbycost[i].attachments = this.statement.ext.statementbycost[i].ext.salesCost.attachments
            this.statement.ext.statementbycost[i].status = this.statement.ext.statementbycost[i].ext.salesCost.status
            this.statement.ext.statementbycost[i].creator = this.statement.ext.statementbycost[i].ext.salesCost.creator
            this.statement.ext.statementbycost[i].updator = this.statement.ext.statementbycost[i].ext.salesCost.updator
            this.costList.push(this.statement.ext.statementbycost[i])
          }
        }
        this.storeSearch(isEmpty(this.condition.channelName) ? null : this.condition.channelName.value, 1, this)
        this.$forceUpdate()
      })
    } else {
      const userData = getUserData()
      if (userData.level == 3) {
        store.dispatch('storesales/getStoreByUserId', {userId: userData.user_id, type: 1})
            .then(response => {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                this.storeArray.push({
                  "label": data[i].store_name + "(" + getCodeLabel('store_store_status', data[i].store_status) + ")",
                  "value": data[i].store_id,
                })
              }

              this.condition.storeName = {
                "label": data[0].store_name + "(" + getCodeLabel('store_store_status', data[0].store_status) + ")",
                "value": data[0].store_id
              }

              this.channelArray = []
              this.condition.channelName = []
              store.dispatch('company/search', {
                status: 4,
                state: 1,
                type: 6,
                store_id: isEmpty(this.condition.storeName) ? 0 : this.condition.storeName.value
              })
                  .then(response => {
                    let data = response.data.data.list
                    for (let i = 0; i < data.length; i++) {
                      this.channelArray.push({"label": data[i].company_name, "value": data[i].company_id})
                    }
                  })
            })
      } else {
        this.storeSearch(null, 1, this)

      }

    }

  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
